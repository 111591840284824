@import "~bootstrap/dist/css/bootstrap.css";
@import '~ngx-toastr/toastr';

*::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0px !important;
}

.ui-accordion .ui-accordion-header a {
  display: block;
  padding: .5em;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-corner {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #6d6d6d;
  border-radius: 10px;
}

.img-logo {
  width: 153px !important;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #3B82F6;
  border-color: #3B82F6;
  color: #ffffff;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  transition: background-color .2s;
  padding:10px;
  font-size:13px;
}

.p-tabview .p-tabview-panels {
  border: 2px solid #f4f4f4;
}

.p-datatable .p-datatable-tbody > tr > td {
  background-color: inherit;
}


@media (min-width: 1000px) {
  .container {
    min-width: 920px;
  }
}

@media (min-width: 1100px) {
  .container {
    min-width: 1020px;
  }
}

@media (min-width: 1200px) {
  .container {
    min-width: 1120px;
  }
}

@media (min-width: 1300px) {
  .container {
    min-width: 1220px;
  }
}

@media (min-width: 1400px) {
  .container {
    min-width: 1320px;
  }
}

@media (min-width: 1500px) {
  .container {
    width: 1420px;
  }
}

@media (min-width: 1600px) {
  .container {
    min-width: 1520px;
  }
}

@media (min-width: 1700px) {
  .container {
    min-width: 1620px;
  }
}

@media (min-width: 1800px) {
  .container {
    min-width: 1720px;
  }
}

@media (min-width: 1900px) {
  .container {
    min-width: 1820px;
  }
}
